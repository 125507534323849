<script setup lang="ts">
import { useSignTypedData } from '@wagmi/vue'

const { closeModal, modal, openModal } = useModal()
const currentStatus = ref<'process' | 'finish' | 'error' | 'wait'>('process')
const { isRemember, step, vesselKey, apiKey, saveKey, isGettingKey, isExportUserKey, clearKey } = useKey()
const { lastConnectWalletType, checkChain } = useConnector()
const { address, initUserInfo, invitation, initStatsUserInfo, clearInvitation } = useUser()
const { currentOpenOrderList } = useOrder()
const { getFaucetInfo } = useFaucet()
const { subscribeBalance } = useWs()
const isFirstLogin = useStorage('isFirstLogin', true)
const { signTypedDataAsync } = useSignTypedData()
const router = useRouter()
const { currentChainId } = useConnector()

const message = useMessage()

function handleCloseSign() {
  isGettingKey.value = false
}

async function signToGetVesselKey() {
  const msgParams = {
    account: address.value,
    domain: {
      chainId: currentChainId.value,
      name: 'Vessel',
    },
    message: {
      nonce: '1',
      origin: location.origin,
      action: 'Vessel Key',
      onlySignOn: SIGN_URL,
    },
    primaryType: 'Vessel',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'chainId', type: 'uint256' },
      ],
      Vessel: [
        { name: 'action', type: 'string' },
        { name: 'onlySignOn', type: 'string' },
        { name: 'nonce', type: 'string' },
      ],
    },
  }

  const params = [address.value, msgParams]
  const method = 'eth_signTypedData_v4'

  try {
    const signature: any = await signTypedDataAsync(msgParams)
    console.log(signature)

    const { privateKey, publicKey } = deriveHDKeyFromEthereumSignature(signature)
    return {
      publicKey: `0x${publicKey?.slice(4)}` || '',
      privateKey: privateKey || '',
    }
  }
  catch (e) {
    console.log(e)

    throw new Error('Error get key')
  }
}

async function SignToGetApiKey(vesselKey: string) {
  const msgParams = {
    account: address.value,
    domain: {
      chainId: currentChainId.value,
      name: 'Vessel',
    },
    message: {
      address: address.value,
      vesselKey,
      action: 'API Key',
      onlySignOn: SIGN_URL,
      nonce: '1',
    },
    primaryType: 'Vessel',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'chainId', type: 'uint256' },
      ],
      Vessel: [
        { name: 'address', type: 'address' },
        { name: 'vesselKey', type: 'string' },
        { name: 'action', type: 'string' },
        { name: 'onlySignOn', type: 'string' },
        { name: 'nonce', type: 'string' },
      ],
    },
  }

  try {
    await new Promise(r => setTimeout(r, 1000))
    const signature: any = await signTypedDataAsync(msgParams)
    return {
      signature,
      msgParams,
    }
  }
  catch (e) {
    console.log(e)
    throw new Error('Error get key')
  }
}

async function sendVesselKeyRequest() {
  try {
    await checkChain()
    step.value = 1
    const vesselKeyPairs = await signToGetVesselKey()
    const { publicKey, privateKey } = vesselKeyPairs
    console.log('vessel public key:\n', publicKey)
    console.log('vessel private key:\n', privateKey)
    step.value = 2
    const apiKeyPairs = await SignToGetApiKey(publicKey)
    const { signature, msgParams } = apiKeyPairs
    step.value = 3
    console.log('api key signature:\n', signature)
    console.log('api key msgParams:\n', msgParams)
    if (isExportUserKey.value) {
      isExportUserKey.value = false
      closeModal('signVesselKey')
      openModal('exportPrivateKey')
      return
    }
    const res = await vesselApi.public.createUser({
      signMessage: JSON.stringify(msgParams),
      signature,
    })
    if (!res.data.error) {
      const data = res.data
      closeModal('signVesselKey')
      vesselKey.value = {
        privateKey,
        publicKey,
        walletAddress: address.value,
        walletType: lastConnectWalletType.value,
      }
      apiKey.value = {
        key: data.key,
        passphrase: data.passphrase,
        secret: data.secret,
        walletAddress: address.value,
        walletType: lastConnectWalletType.value,
      }
      if (isRemember.value) {
        saveKey(address.value)
      }
      initNewWalletAddress()
      isGettingKey.value = false

      if (isFirstLogin.value) {
        openModal('voyage')
        isFirstLogin.value = false
      }

      if (invitation.value.isNewCode) {
        await vesselApi.stats.bindCode({
          code: invitation.value.code,
          address: address.value,
        }).then((res) => {
          if (res.data.error) {
            openModal('invitationCodeExpired')
            clearInvitation()
            clearKey(address.value)
            router.replace('/login')
          }
          else {
            initStatsUserInfo()
            modal.value.voyage = true
          }
        })
      }
    }
    else {
      message.error(res.data.msg)
      step.value = 0
      isExportUserKey.value = false
      isGettingKey.value = false
    }
  }
  catch (e: any) {
    step.value = 0
    console.log(e)

    // isExportUserKey.value = false
    isGettingKey.value = false
    // closeModal('signVesselKey')
    throw new Error('Key get error')
  }
}

whenever(computed(() => modal.value.signVesselKey), () => {
  checkChain()
}, {
  immediate: true,
})
</script>

<template>
  <v-modal
    v-model:show="modal.signVesselKey"
    :title="isExportUserKey ? 'Export Private Key' : 'Start Onboarding'" modal-class="text-grey1"
    @close="handleCloseSign"
  >
    <div v-if="isExportUserKey">
      Please review your wallet for a pending export request.
    </div>
    <template v-else>
      <div>You will receive two signature request.</div>
      <div>Signing is free and secure.</div>
    </template>
    <n-steps
      vertical :current="step" :status="currentStatus"
      class="mt-0.32 text-0.14 font-700 font-dm"
    >
      <n-step
        title="Verify Ownership"
        description="Please confirm that this wallet belongs to you."
      />
      <n-step
        title="Enable Trading"
        description="Enable secure API access for swift and efficient trading."
      />
    </n-steps>
    <n-checkbox v-if="!isExportUserKey" v-model:checked="isRemember" class="mt-0.32">
      <div class="flex items-center gap-x-0.04">
        Remember ME
        <n-tooltip trigger="hover" :width="224">
          <template #trigger>
            <svg-info />
          </template>
          <div class="text-0.12 font-500 leading-0.2 font-poppins">
            Use this option on your personal device only, to keep your information secure. Avoid shared or public computers.
          </div>
        </n-tooltip>
      </div>
    </n-checkbox>
    <v-button v-if="step === 0" class="mt-0.32" @click="sendVesselKeyRequest">
      Send Request
    </v-button>
    <v-button v-else :loading="true" class="mt-0.32">
      Sign in Metamask
    </v-button>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
