export const VAULT_ADDRESS = import.meta.env.VITE_VAULT_ADDRESS
export const CHAIN_ID = +import.meta.env.VITE_VAULT_CHAIN_ID
export const CHAIN_NAME = import.meta.env.VITE_VAULT_CHAIN_NAME
export const CHAIN_RPC = import.meta.env.VITE_VAULT_CHAIN_RPC
export const CURRENCY_NAME = import.meta.env.VITE_VAULT_CURRENCY_NAME
export const CURRENCY_DECIMALS = +import.meta.env.VITE_VAULT_CURRENCY_DECIMALS
export const VESSEL_LANDING_PAGE = import.meta.env.VITE_VESSEL_LANDING_PAGE
export const SIGN_URL = import.meta.env.VITE_VAULT_SIGN_URL || 'https://trade.vessel.finance'
export const EXPLORER_URL = import.meta.env.VITE_EXPLORER_URL || 'https://sepolia.etherscan.io/tx'
export const IS_MAINNET = import.meta.env.VITE_IS_MAINNET === 'true'
export const TESTNET_URL = import.meta.env.VITE_TESTNET_URL
export const MAINNET_URL = import.meta.env.VITE_MAINNET_URL
export const GITBOOK_URL = 'https://vesselfinance.gitbook.io/vessel'
export const APIDOC_URL = 'https://apidoc.vessel.finance/'
export const TELEGRAM_URL = 'https://t.me/vesselfinance'
export const DISCORD_URL = 'https://discord.gg/vesselfinance'
export const BLOG_URL = 'https://medium.com/vesselfinance'
export const WALLET_CONNECT_PROJECT_ID = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID

export const ORDER_PAGE_SIZE = 6

export { default as dayjs } from 'dayjs'
