import {
  estimateFeesPerGas,
  getAccount,
  readContract,
  writeContract,
} from '@wagmi/core'
import {
  parseUnits,
} from 'viem'

import VAULT_ABI from '~/abi/vault.json'
import ERC20_ABI from '~/abi/erc20.json'
import { getWagmiConfig } from '~/lib/wagmi'

export async function writeVault(functionName: string, args: any[], value?: bigint) {
  const config = getWagmiConfig()
  const account = getAccount(config)
  const { currentVaultAddress } = useConnector()
  const result = await estimateFeesPerGas(config)

  return writeContract(config, {
    abi: VAULT_ABI,
    functionName,
    address: currentVaultAddress.value as `0x${string}`,
    account: account.address,
    args,
    value,
    chain: account.chain,
    maxFeePerGas: result.maxFeePerGas,
    maxPriorityFeePerGas: result.maxPriorityFeePerGas,
  })
}

export async function approveAmount(address: `0x${string}`, decimal: number, amount: string) {
  const config = getWagmiConfig()
  const account = getAccount(config)
  const { currentVaultAddress } = useConnector()
  const result = await estimateFeesPerGas(config)
  return writeContract(config, {
    abi: ERC20_ABI,
    functionName: 'approve',
    address,
    account: account.address,
    args: [
      currentVaultAddress.value,
      parseUnits(amount, decimal),
    ],
    chain: account.chain,
    maxFeePerGas: result.maxFeePerGas,
    maxPriorityFeePerGas: result.maxPriorityFeePerGas,
  })
}

export async function faucetMint(address: `0x${string}`, amount: bigint) {
  const config = getWagmiConfig()
  const account = getAccount(config)
  const result = await estimateFeesPerGas(config)
  return writeContract(config, {
    abi: ERC20_ABI,
    functionName: 'mint',
    address,
    account: account.address,
    args: [
      amount,
    ],
    chain: account.chain,
    maxFeePerGas: result.maxFeePerGas,
    maxPriorityFeePerGas: result.maxPriorityFeePerGas,
  })
}

export async function getBalanceFromChain(address: `0x${string}`) {
  const config = getWagmiConfig()
  const account = getAccount(config)
  return readContract(config, {
    abi: ERC20_ABI,
    address,
    functionName: 'balanceOf',
    account: account.address,
    args: [
      account.address,
    ],
    chainId: account.chainId,
  })
}

export async function getAllowanceFromChain(address: `0x${string}`) {
  const config = getWagmiConfig()
  const account = getAccount(config)
  const { currentVaultAddress } = useConnector()
  return readContract(config, {
    abi: ERC20_ABI,
    address,
    functionName: 'allowance',
    account: account.address,
    args: [
      account.address,
      currentVaultAddress.value,
    ],
    chainId: account.chainId,
  })
}
