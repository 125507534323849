const ommApi = {
  getPoolOverviewList: (params: PoolListReq) => http.get<A<PoolListOverviewRes>>('/gateway/public/pool', { params }),
  // getPoolList: (params: PoolListReq) => http.get<A<PoolListRes>>('/amm/detailedPools', { params }),
  getPoolDetailList: (params: PoolListReq) => http.get<A<PoolDetailListRes>>('/gateway/public/pool/detail'),
  addPool: (data: AddPoolReq, options: any) => http.post('/amm/add', data, options),
  getUserPosition: () => http.get('/amm/user/positions'),
  getPoolHistory: () => http.get<A<PoolHistoryRes>>('/gateway/user/history/ammUserAction'),
  collectFee: (data: AddPoolReq, options: any) => http.post('/amm/collectFee', data, options),
  removePool: (data: AddPoolReq, options: any) => http.post('/amm/remove', data, options),
  getPoolLiquidity: (poolId: number) => http.get<LiquidityRes>(`/gateway/public/pool/liquidity/${poolId}`),
  getPoolPerformance: (poolId: number) => http.get<PerformanceRes>(`/gateway/public/pool/performance/${poolId}`),
}

export default ommApi

interface PoolDetailListRes {
  pools: PoolDetailItem[]
}

export interface PoolDetailItem {
  maxPositionSize: number
  maxTick: string
  minTick: string
  operatorFeeRate: string
  poolId: number
  symbol: string
  tickSpacing: string
  priceRange: string
}

interface PoolListReq {
  symbol?: string
  poolId?: number
}

export interface PoolItem {
  poolId: number
  symbol: string
  minTick: number
  maxTick: number
  tickSpacing: number
  maxPositionSize: number
  operatorFeePercentage: number
  baseTVL: number
  quoteTVL: number
  priceTickLeft: number
  priceTickLeftLiquidity: number
  priceTickLiquidity: number
}

export interface PoolBoost {
  boost: number
  startTime: string
  endTime: string
}
export interface PoolOverviewItem {
  apr: number
  baseTokenAddress: string
  baseTokenName: string
  baseTvl: number
  baseTvlValue: number
  feeValue24h: number
  poolId: number
  quoteTokenAddress: string
  quoteTokenName: string
  quoteTvl: number
  quoteTvlValue: number
  tradeVolume24h: number
  boost: PoolBoost
}

interface PoolHistoryRes {
  history: PoolHistoryItem[]
}

interface PoolListRes {
  pools: PoolItem[]
}

interface PoolListOverviewRes {
  pools: PoolOverviewItem[]
}

export interface AddPoolReq {
  'poolId': number
  'tickIndexL': number
  'tickIndexR': number
  'baseAmount': number
  'quoteAmount': number
  'nonce': number
  'signature': string
}

export interface UserPositionItem {
  'poolId': number
  'tickL': number
  'tickR': number
  'baseAmount': number
  'quoteAmount': number
  'baseFeeAmount': number
  'quoteFeeAmount': number
}

export interface PoolHistoryItem {
  baseAssetAmount?: number
  baseAssetName: string
  poolId?: number
  quoteAssetAmount?: number
  quoteAssetName: string
  tickL?: number
  tickR?: number
  timestamp?: number
  type: string
}

export interface LiquidityItem {
  startTick: string
  endTick: string
  baseTokenAmount: string
}

interface LiquidityRes {
  ticks: LiquidityItem[]
}

export interface PerformanceItem {
  dt: string
  fee: number
  tvl: number
  volume: number
}

interface PerformanceRes {
  data: PerformanceItem[]
}
