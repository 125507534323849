import type { GlobalThemeOverrides } from 'naive-ui'

export const themeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: '#37AAEC',
    fontFamily: 'Poppins',
  },
  Pagination: {
    itemTextColorHover: 'var(--vessel-color-primary)',
  },
  Dropdown: {
    color: 'var(--vessel-color-black-2)',
    optionColorHover: 'var(--vessel-color-black-3)',
    padding: '0px 0px',
    borderRadius: 'calc(12rem / 400)',
    optionTextColor: 'var(--vessel-color-white-2)',
  },
  Steps: {
    headerTextColorProcess: 'var(--vessel-color-white-2)',
    headerTextColorFinish: 'var(--vessel-color-primary)',
    stepHeaderFontWeight: '700',
    descriptionTextColorFinish: 'var(--vessel-color-grey-1)',
    descriptionTextColorProcess: 'var(--vessel-color-grey-1)',
  },
  Notification: {
    padding: '16px 32px',
    color: 'var(--vessel-color-black-2)',
    headerTextColor: 'var(--vessel-color-white-2)',
    titleFontSize: 'calc(24rem / 400)',
    textColor: 'var(--vessel-color-grey-1)',
    borderRadius: 'calc(20rem / 400)',
  },
  Select: {
    peers: {
      InternalSelection: {
        border: '2px solid var(--vessel-color-black-3)',
        borderActive: '2px solid var(--vessel-color-primary)',
        borderHover: '2px solid var(--vessel-color-grey-1)',
        borderFocus: '2px solid var(--vessel-color-primary)',
        boxShadowActive: 'none',
        boxShadowFocus: 'none',
        color: 'var(--vessel-color-black-2)',
        heightMedium: 'calc(48rem / 400)',
        borderRadius: 'calc(12rem / 400)',
        peers: {
          Popover: {
            color: 'var(--vessel-color-black-3)',
          },
        },
      },
      InternalSelectMenu: {
        color: 'var(--vessel-color-black-3)',
        optionHeightMedium: '48px',
        optionTextColor: 'var(--vessel-color-white-2)',
        optionTextColorActive: 'var(--vessel-color-primary)',
        optionTextColorPressed: 'var(--vessel-color-primary)',
        optionColorPending: 'var(--vessel-color-grey-1)',
        optionColorActivePending: 'var(--vessel-color-grey-1)',
      },
    },
  },
  Tooltip: {
    color: 'var(--vessel-color-grey-1)',
    textColor: 'var(--vessel-color-white-2)',
    borderRadius: 'calc(8rem / 400)',
  },
  Popover: {
    color: 'var(--vessel-color-black-3)',
    textColor: 'var(--vessel-color-white-2)',
    borderRadius: 'calc(8rem / 400)',
  },
  Drawer: {
    color: 'var(--vessel-color-black-2)',
  },
  DatePicker: {
    peers: {
      Input: {
        color: 'transparent',
        borderHover: 'none',
        borderFocus: 'none',
        colorFocus: 'transparent',
        boxShadowFocus: 'none',
        textColor: 'var(--vessel-color-grey-1)',
        placeholderColor: 'var(--vessel-color-grey-1)',
        fontSizeSmall: 'calc(12rem / 400)',
      },
      Button: {
        colorHover: 'transparent',
        color: 'var(--vessel-color-primary)',
        border: 'none',
        borderHover: 'none',
        textColor: 'var(--vessel-color-white-2)',
        textColorHover: 'var(--vessel-color-white-2)',
        textColorPrimary: 'var(--vessel-color-white-2)',
        textColorHoverPrimary: 'var(--vessel-color-white-2)',
        colorPrimary: 'var(--vessel-color-primary)',
        colorHoverPrimary: 'var(--vessel-color-primary)',
        borderPrimary: 'none',
        borderHoverPrimary: 'none',
        textColorFocus: 'var(--vessel-color-white-2)',
      },

      TimePicker: {
        peers: {
          Button: {
            colorHover: 'var(--vessel-color-primary)',

          },
        },
      },
    },
  },
  Switch: {
    railColorActive: 'var(--vessel-color-primary)',
  },
  Upload: {
    itemIconColor: 'var(--vessel-color-primary)',
    itemTextColorError: 'var(--vessel-color-primary)',
    itemBorderImageCardError: 'var(--vessel-color-primary)',
    peers: {
      Button: {
        colorError: 'var(--vessel-color-primary)',
      },
    },
  },
}
