<script setup lang="tsx">
import { useRouteQuery } from '@vueuse/router'
import type { SelectOption } from 'naive-ui'
import { parseUnits } from 'viem'
import { waitForTransactionReceipt } from '@wagmi/core'
import VIcon from '@/components/v/Icon.vue'
import { getWagmiConfig } from '~/lib/wagmi'

const { modal, closeModal } = useModal()

const { currencyList } = useSymbol()
const { address } = useUser()
const { checkChain } = useConnector()

const notification = useNotification()

const current = ref()
const claimableBalance = ref('0')
const isLoadingClaimable = ref(false)
const isClaim = ref(false)
const assetId = useRouteQuery('assetId')
const symbol = useRouteQuery('symbol')

const currentItem = computed(() => currencyList.value.find(i => i.assetId === current.value))

const disabled = computed(() => !+claimableBalance.value || isLoadingClaimable.value)

async function getClaimableBalance() {
  try {
    isLoadingClaimable.value = true
    const contract = await getContract(VAULT_ADDRESS)
    const res = await (contract.methods.getPendingWithdraw as any)(address.value, currentItem.value.assetId).call()
    isLoadingClaimable.value = false
    claimableBalance.value = dividedByPower(res, currentItem.value.onChainDecimal)
  }
  catch (e: any) {
    console.log(e)
  }
}

function onChangeCurrency() {
  claimableBalance.value = ''
  getClaimableBalance()
}

async function claim() {
  await checkChain()
  if (!disabled.value) {
    try {
      const claimValue = parseUnits(claimableBalance.value, currentItem.value.onChainDecimal)
      isClaim.value = true
      await writeVault('withdraw', [currentItem.value.assetId, claimValue]).then((hash) => {
        notification.success({
          title: 'Claim Completed',
          content: `Your claim of ${claimableBalance.value} ${currentItem.value.assetName} has been successfully processed.`,
          duration: 2000,
        })
        waitForTransactionReceipt(getWagmiConfig(), { hash }).then(() => {
          notification.success({
            title: 'Claim Submitted',
            content: `Please wait for the blockchain confirmation for your claim of ${claimableBalance.value} ${currentItem.value.assetName}.`,
            duration: 2000,
          })
        })
      })
      closeModal('claim')
    }
    catch (e) {
      isClaim.value = false
      console.log(e)
    }
  }
}

function renderLabel(option: SelectOption) {
  return (
    <div class="flex items-center justify-start">
      <VIcon currency={option.assetName as string} class="mr-0.08 h-0.24 w-0.24" />
      {option.assetName}
    </div>
  )
}

whenever(() => modal.value.claim, async () => {
  checkChain()
  if (assetId.value) {
    current.value = +assetId.value
  }
  else if (symbol.value) {
    const pairs = getCurrencyFromSymbol(symbol.value as string)
    current.value = +pairs.baseId
  }
  else if (!current.value) {
    current.value = currencyList.value?.[0]?.assetId
  }
  onChangeCurrency()
})
</script>

<template>
  <v-modal
    v-model:show="modal.claim"
    modal-class="w-4.48"
    title="Claim"
  >
    <div class="mb-0.12 text-0.16 text-grey1">
      Select Token
    </div>
    <n-select
      v-model:value="current"
      :options="currencyList"
      :render-label="renderLabel"
      filterable
      label-field="assetName" value-field="assetId"
      @update:value="onChangeCurrency"
    />
    <div class="mb-0.12 mt-0.32 text-0.16 text-grey1">
      Amount
    </div>
    <div class="h-0.48 w-full flex items-center rd-0.12 bg-black3 px-0.16 text-grey1">
      <svg-loading v-if="isLoadingClaimable" class="animate-spin animate-duration-2000" />
      {{ claimableBalance }}
    </div>

    <template #footer>
      <v-button
        class="mt-0.32 w-full"
        :loading="isClaim"
        :disabled="disabled" @click="claim"
      >
        Confirm Claim
      </v-button>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
