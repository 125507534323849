<script setup lang="ts">
import { useConfig } from '@wagmi/vue'

const { closeModal: close, modal } = useModal()
const { currentConnector, currentChainId } = useConnector()

const config = useConfig()

const currentChainConfig = computed(() => config.chains.find(i => i.id === currentChainId.value))

function closeModal() {
  close('changeChainID')
}

async function changeChain() {
  currentConnector.value.switchChain({
    chainId: currentChainId.value,
  }).then(closeModal)
}
</script>

<template>
  <v-modal
    v-model:show="modal.changeChainID"
    modal-class="w-5.5 text-grey1"
    :z-index="201"
    title="Wrong network"
  >
    <div>Please change your network to:</div>
    <div>Chain ID: {{ currentChainConfig.id }}</div>
    <div>Chain Name: {{ currentChainConfig.name }}</div>
    <template #footer>
      <div class="mt-0.32 flex gap-x-0.16">
        <v-button type="outline" class="flex-1" @click="closeModal">
          Cancel
        </v-button>
        <v-button class="flex-1" @click="changeChain">
          Change
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
