import type { CurrencyItem, SymbolItem, TickerSymbol } from '~/service/modules/public'

export const useSymbol = createGlobalState(() => {
  const currentSymbol = useStorage<string>('currentSymbol', null)
  const symbolList = ref<SymbolItem[]>([])
  const currencyList = ref<CurrencyItem[]>([])
  const tickerInfo = ref<TickerSymbol[]>([])

  const currentSymbolItem = computed(() => symbolList.value.find(i => i.symbol === currentSymbol.value))
  const currentTickerItem = computed(() => tickerInfo.value.find(i => i.symbol === currentSymbol.value))

  vesselApi.public.getSymbols().then((res) => {
    symbolList.value = res.data.results
    if (!currentSymbol.value || !symbolList.value.some(i => i.symbol === currentSymbol.value)) {
      currentSymbol.value = symbolList.value[0]?.symbol
    }
  })
  vesselApi.public.getCurrency().then((res) => {
    currencyList.value = res.data.assets
  })
  vesselApi.public.getTickerInfo().then((res) => {
    tickerInfo.value = res.data.symbols
  })

  async function refreshTickerInfo() {
    return vesselApi.public.getTickerInfo().then((res) => {
      tickerInfo.value = res.data.symbols
    })
  }

  function changeCurrentSymbol(symbol: string) {
    currentSymbol.value = symbol
  }

  return {
    symbolList,
    currencyList,
    currentSymbol,
    currentSymbolItem,
    changeCurrentSymbol,
    currentTickerItem,
    tickerInfo,
    refreshTickerInfo,
  }
})
