import type { CurrentOrderList } from './order'

const userApi = {
  getUserInfo: () => http.get<UserInfo>('/user'),
  verifyPassword: (params: { code: string }) => http.get('/stats/authorizationCode', { params }),
  getAdminSignature: () => http.get<AdminSignature>('/registration'),
  getDepositHistory: () => http.get<DepositRes>('/gateway/user/history/deposit'),
  getWithdrawHistory: () => http.get<WithdrawRes>('/gateway/user/history/withdraw'),
  getClaimHistory: () => http.get<ClaimRes>('/gateway/user/history/claim'),
  getTransferHistory: () => http.get<TransferRes>('/gateway/user/history/userAction'),
  getAsset: () => http.get<AssetRes>('/userAsset'),
  withdraw: (data: WithdrawReq) => http.post('/withdraw', data),
  getWithdrawEstTime: (id: number) => http.get('/stats/prover/withdraw/estimate', { params: { withdrawGlobalEventId: id } }),
}

export default userApi

export interface ClaimItem {
  address: string
  assetName: string
  amount: number
  timestamp: number
}

export interface ClaimRes {
  claims: ClaimItem[]
}

interface WithdrawReq {
  assetName: string
  amount: number
  clientOrderId: string
}

export interface WithdrawItem extends DepositItem {
  withdrawOrderId: string
}
export interface WithdrawRes {
  withdraws: WithdrawItem[]
}

export interface AssetItem {
  assetName: string
  available: number
  inUse: number
  pendingWithdraw: number
  price: number
  todayTransactionAmount: number
}

export interface AssetRes {
  assets: AssetItem[]
}

export interface DepositItem {
  address: string
  amount: number
  assetName: string
  id: number
  status: number
  timestamp: number
  transactionFee: string
}
export interface DepositRes {
  deposits: DepositItem[]
}
export interface TransferItem {
  address: string
  amount: number
  assetName: string
  globalEventId?: number
  id?: number
  status?: number
  timestamp: number
  transaction: string
  transactionFee?: string
  type: string
  withdrawOrderId?: string
}
export interface TransferRes {
  history: TransferItem[]
}

interface AdminSignature {
  message: string
  operator: string
  signature: string
}

export type OrderType = 'LIMIT' | 'MARKET'

export interface Balance {
  asset: string
  balance: number
}

export interface OrderItem {
  executedQty: number
  orderId: number
  origQty: number
  price: number
  side: 'SELL' | 'BUY'
  symbol: string
  timeInForce: string
  type: OrderType
  workingTime: number
}

export interface UserOrder {
  symbol: string
  orders: OrderItem[]
}

export interface UserInfo {
  token: string
  id: number
  address: string
  status: string
  balances: Balance[]
  orders: UserOrder[]
  takerFeeRate: number
  makerFeeRate: number
  currentOpenOrders: CurrentOrderList[]
}
