const orderApi = {
  placeOrder: (data: PlaceOrderRequest, options: any) => http.post('/order', data, options),
  modifyOrder: (data: ModifyOrderRequest, options: any) => http.post('/modifyOrder', data, options),
  getOpenOrder: (params: any) => http.get<OrderRes>('/currentOpenOrders', { params }),
  getOrderHistory: (params: any) => http.get<OrderRes>('/gateway/user/history/order', { params }),
  getTradeHistory: (params: any) => http.get<TradeRes>('/gateway/user/myTrades', { params }),
  cancelOrder: (params: CancelOrderReq) => http.delete('/order', { params }),
  cancelAllOrder: (params: CancelOrderReq) => http.delete('/cancelAllOrders', { params }),
}

export default orderApi

interface CancelOrderReq {
  symbol: string
  address: string
  orderId: number
}

export interface CurrentOrderList {
  symbol: string
  orders: OrderItem[]
}

export type OrderStatus =
  'NEW' |
  'PARTIALLY_FILLED' |
  'FILLED' |
  'CANCELED' |
  'PENDING_CANCEL' |
  'REJECTED' |
  'EXPIRED' |
  'EXPIRED_IN_MATCH'

export interface OrderItem {
  orderId: number
  clientOrderId: string
  cumulativeQuoteQty: number
  executedQty: number
  origQty: number
  price: number
  side: 'SELL' | 'BUY'
  status: OrderStatus
  symbol: string
  timeInForce: string
  type: string
  workingTime: number
  orderTime: number
}

export interface OrderRes {
  orders: OrderItem[]
}

export interface TradeItem {
  id: number
  isBuyerMaker: boolean
  isMaker: boolean
  price: string
  qty: string
  quoteQty: string
  time: number
  side: 'BUY' | 'SELL'
  fee: number
  feeCurrency: string
  symbol: string
}

export interface TradeRes {
  trades: TradeItem[]
}

export interface OrderReq {
  symbol: string
  side: string
  type: string
  timeInForce?: string
  quantity: number
  price: number
  clientOrderId: string
  signature: string
}

export interface ModifyOrderRequest {
  newClientOrderId: string
  newOrderRespType: string
  oldOrderId: number
  price: number
  quantity: number
  signature: string
  symbol: string
}

export interface PlaceOrderRequest {
  clientOrderId: string
  /**
   * default FULL，"ACK", "RESULT", "FULL"
   */
  newOrderRespType?: string
  /**
   * Required if type = LIMIT
   */
  price?: number
  quantity: number
  /**
   * BUY or SELL
   */
  side: 'BUY' | 'SELL'
  signature: string
  symbol: string
  /**
   * Required if type = LIMIT, should be GTC, IOC, FOK
   */
  timeInForce?: string
  type: 'LIMIT' | 'MARKET'
}

export type TIF = 'GTC' | 'IOC' | 'FOK'
