import BN from 'bignumber.js'

export async function disconnectWallet() {
  const { address, clearUserInfo, initAddress } = useUser()
  const { assetBalance, closeAssetBalanceWs } = useWs()
  const registeredAddress = useStorage('REGISTERED_ADDRESS', [])
  const connector = useConnector()
  const { clearKey } = useKey()
  const { currentOpenOrderList } = useOrder()
  clearKey(initAddress.value)
  connector.disconnect()
  clearUserInfo()
  closeAssetBalanceWs()
  registeredAddress.value = registeredAddress.value.filter(i => i !== address.value)
  currentOpenOrderList.value = []
  assetBalance.value = []
  initAddress.value = ''
}

export function initNewWalletAddress() {
  const { address, initUserInfo, clearUserInfo, userInfo, initAddress, initStatsUserInfo } = useUser()
  const { initKey } = useKey()
  const { currentOpenOrderList, getCurrentTabOrder } = useOrder()
  const { getFaucetInfo } = useFaucet()
  const { subscribeBalance } = useWs()
  if (initAddress.value === address.value || !address.value) {
    return
  }
  clearUserInfo()
  const isSigned = initKey(address.value)
  if (isSigned) {
    initUserInfo().then(() => {
      initStatsUserInfo()
      currentOpenOrderList.value = userInfo.value.currentOpenOrders.reduce((res, i) => [...res, ...i.orders], [])
    })
    getFaucetInfo()
    subscribeBalance()
    getCurrentTabOrder()
    initAddress.value = address.value
  }
  else {
    checkAndBindVesselKey()
  }
}

export function checkAndBindVesselKey() {
  const { run: verifyAddress } = useHttp(vesselApi.stats.verifyAddress)
  const { address, invitation, initStatsUserInfo } = useUser()
  const { getVesselKey } = useKey()
  const { openModal } = useModal()

  console.log(address.value)

  verifyAddress({
    address: address.value,
  }).then((res) => {
    if (!res.data.isValid) {
      if (!invitation.value.code) {
        openModal('walletNotBound')
      }
      else {
        invitation.value.isNewCode = true
        getVesselKey()
      }
    }
    else {
      invitation.value.isNewCode = false
      invitation.value.isValid = true
      invitation.value.code = ''
      if (location.pathname === '/login') {
        location.href = '/trade'
      }
      getVesselKey()
    }
  })
}

export function getCurrencyFromSymbol(symbol: string) {
  const { symbolList } = useSymbol()
  const symbolItem = symbolList.value.find(i => i.symbol === symbol)
  return symbolItem
}

export function getPrecisionFromSymbol(symbol: string) {
  const { tickerInfo } = useSymbol()
  const tickerItem = tickerInfo.value.find(i => i.symbol === symbol)
  if (!tickerItem) {
    return {
      price: 2,
      total: 4,
      base: 2,
      value: 4,
      amount: 2,
    }
  }
  return {
    price: tickerItem.quoteAssetPrecision - tickerItem.baseAssetPrecision,
    total: tickerItem.quoteAssetPrecision,
    value: tickerItem.quoteAssetPrecision,
    quote: tickerItem.quoteAssetPrecision,
    amount: tickerItem.baseAssetPrecision,
    base: tickerItem.baseAssetPrecision,
  }
}

export function getUserFee(symbol: string) {
  const { userInfo } = useUser()

  const { tickerInfo } = useSymbol()

  if (!symbol || !userInfo.value) {
    return {
      takerFee: 0,
      makerFee: 0,
    }
  }
  const tickerItem = tickerInfo.value?.find(i => i.symbol === symbol)

  if (!tickerItem) {
    return {
      takerFee: userInfo.value.takerFeeRate,
      makerFee: userInfo.value.makerFeeRate,
    }
  }
  return {
    takerFee: +BN(userInfo.value.takerFeeRate).multipliedBy(tickerItem.takerFeeDiscount).dividedBy(BN(100)).toFixed(6),
    makerFee: +BN(userInfo.value.makerFeeRate).multipliedBy(tickerItem.makerFeeDiscount).dividedBy(BN(100)).toFixed(6),
  }
}
